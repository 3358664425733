import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NavigationBar from './sideBarMenu';
import '../styles/style.css';
import '../styles/Responsive.css';
import '../styles/style_bundle.css';
import ButtonGroup from './buttonGroup';
import MyDocuments from './myDocuments';
import NavigationNames from "./navigationNames";
import { refreshTokenAction } from '../../actions/refreshTokenAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Statistics from './statistics';
import { StatisticId } from '../../actions/statisticsId';
import Rename from './rename';
import PaymentsBox from './paymentBoxs';

export default function Payments() {
    const dispatch = useDispatch();
    const getUserInformationReducer = useSelector(state => state.getUserInformationReducer);
    const refreshTokenReducer = useSelector(state => state.refreshTokenReducer);
    const newPdfRedux = useSelector(state => state.newPdfRedux);
    const [statisticsFlag, setStatisticsFlag] = useState(false);
    const statisticIdReducer = useSelector(state => state.StatisticIdReducer);
    const DeleteMoreThanOneDocumentReducer = useSelector(state => state.DeleteMoreThanOneDocumentReducer);
    const ShowModalFlagReducer = useSelector(state => state.ShowModalFlagReducer);
    const [renameDocumentActive, setRenameDocumentActive] = useState(false);
    const [isOn, setIsOn] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [isMobileView, setIsMobileView] = useState(false); // Ekran boyutuna göre ayarlama
    const CreateChargeReducer = useSelector(state => state.CreateChargeReducer);

    const toggleSwitch = () => {
        setIsOn(!isOn);
    }

    const handleClick = (id) => {
        setSelectedId(id);
    };

    useEffect(() => {

        setTimeout(function () { window.location.href = CreateChargeReducer.data.chargeUrl; }, 200);

    }, [CreateChargeReducer]);
    // Ekran geniþliði 990px'den küçükse mobil görünüm için sidebar'ý gizlemek
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 990) {
                setIsMobileView(true);                
            } else {
                setIsMobileView(false);                
            }
        };

        // Ýlk yüklemede ve her yeniden boyutlandýrmada kontrol eder
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const paymentPlans = [
        { id: 1, paymentPlan: "Starter Pack 100 Credits", price: "1.00" },
        { id: 2, paymentPlan: "Professional Pack 500 Credits", price: "5.00" },
        { id: 3, paymentPlan: "Premium Pack 1000 Credits", price: "10.00" },
        { id: 4, paymentPlan: "Enterprise Pack 10000 Credits", price: "100.00" },
    ];
    return (
        <div className="mainDivRsT">
            <NavigationBar />
            <section className='app-wrapper w-100 mb-3'>
                <div className="pb-8 pb-md-11 mt-md-n6">
                    <div className="currentMenuItemP">
                        <ToastContainer />
                        <div className="cvFgHT container">
                            <div className='headerP d-flex'>                                
                                <div className="CvGhT">
                                    <br />                                    
                                    <h1>Payment Plans</h1>
                                </div>
                                
                            </div>
                            <div className="tab-content top-border-table oTyyQ">
                                <div className="tab-pane d-flex col-three" style={{ 'flexDirection': isMobileView ? 'column-reverse' :'initial'   }}>
                                    {paymentPlans.map((plan) => (
                                        <PaymentsBox
                                            key={plan.id} // Her kutu için unique bir key
                                            isMobileView={isMobileView}
                                            paymentPlan={plan.paymentPlan}
                                            price={plan.price}
                                            isSelected={selectedId === plan.id} // Seçili olup olmadığını kontrol et
                                            onClick={() => handleClick(plan.id)} // Tıklama işlevini geç
                                        />
                                    ))}
                                </div>                               
                            </div>
                            <div>
                                <h4>Secure and Seamless Payments with Bymmy</h4>
                                <p>At Bymmy, we are committed to providing you with a secure, transparent, and seamless payment experience as you purchase credits to unlock the features you love. With the power of blockchain technology and our integration with Coinbase Commerce, you can rest assured that every transaction is safe, reliable, and fully verifiable on-chain.</p>

                                <h4>How Payments Work</h4>

                                <p>Bymmy allows you to purchase credit packs ranging from $1.00 to $100.00. For every $1.00 spent, you will receive 10 credits for each dollar that you can use within the app. Here's how the process works:</p>

                                <p>Choose Your Pack: Select a credit pack that suits your needs.</p>

                                <p>Make Your Payment: Complete your purchase using any of the following supported methods:</p>

                                <ul>
                                    <li>Coinbase Account</li>

                                    <li>Coinbase Wallet</li>

                                    <li>Metamask</li>

                                    <li>Smart Wallets</li>
                                </ul>
                                <p>Payments can be made via popular blockchain networks including Base, Polygon, and Ethereum with a variety of supported cryptocurrencies.</p>

                                <p>On-Chain Security: Once you complete your transaction, Coinbase Commerce securely records the payment information on-chain. This ensures that every transaction is verifiable and permanent, eliminating any risk of loss or mismanagement.</p>

                                <p>Instant Confirmation: Once the payment is confirmed on-chain, Coinbase Commerce will notify Bymmy via a secure webhook. Your credits will be instantly added to your account.</p>

                                <h4>Why Trust Our Payment Process?</h4>

                                <p>Blockchain Security: Payments are handled through Coinbase Commerce, a trusted and secure blockchain-based payment solution. Every transaction is stored on-chain, ensuring 100% transparency and reliability.</p>

                                <p>No Loss, No Hassle: On-chain storage guarantees that no payment will ever be lost. Once a transfer is confirmed, it cannot be tampered with or reversed.</p>

                                <p>Multiple Crypto Options: Pay seamlessly using your preferred cryptocurrency and wallet across supported networks (Base, Polygon, Ethereum).</p>

                                <p>Fast and Reliable Webhooks: With Coinbase's webhook integration, your credits are automatically and securely added to your account within moments of confirmation.</p>

                                <p>Verified by Coinbase: Coinbase is one of the most reputable names in the crypto industry, trusted by millions of users worldwide for secure blockchain transactions.</p>
                                <h4>Your Safety Is Our Priority</h4>

                                <p>We understand that security and trust are essential when it comes to payments. By leveraging Coinbase Commerce and blockchain technology, we ensure that your transactions are:</p>
                                <ul>
                                    <li>Tamper-Proof: Immutable and permanently stored on-chain.</li>

                                    <li>Transparent: Fully traceable and verifiable.</li>

                                    <li>Secure: Protected by advanced blockchain security protocols.</li>
                                </ul>
                                <p>With Bymmy and Coinbase Commerce, you can confidently purchase credits knowing your payments are safe, transparent, and handled with the highest standards.</p>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
//<Rename show="true" />
                            /*
                                 <>
                {renameDocumentActive && <Rename show="true" />}
                {statisticsFlag && <Statistics id={statisticIdReducer.data} />}
            </>*/
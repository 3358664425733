import React, { useState, useEffect } from 'react';
import Modal from "./modal";
import { useSelector, useDispatch } from 'react-redux';
import { TroubleShootingAction } from '../../actions/troubleShootingAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDropzone } from 'react-dropzone'
import '@fortawesome/fontawesome-free/css/all.min.css';
import NavigationBar from './sideBarMenu';
import styled from 'styled-components';

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#52e3ff';
    }
    if (props.isDragReject) {
        return '#efbdbe';
    }
    if (props.isFocused) {
        return '#b7ccd0';
    }
    return '#eeeeee';
}

const Container = styled.div`
      background-color: ${(props) => getColor(props)};
      opacity: 0.8;
    `;

const TroubleShooting = (props) => {
    const [uploadedFile, setUploadedFile] = useState([]);
    const [troubleShooting, setTroubleShooting] = useState('');
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(props.show);
    const ShowModalFlagReducer = useSelector(state => state.ShowModalFlagReducer);
    const troubleShootingReducer = useSelector(state => state.troubleShootingReducer);
    const [isDescFlag, setIsDescFlag] = useState(false);
    const [documentTitle, setDocumentTitle] = useState("Submit a Problem");
    const [isTroubleShooting, setIsTroubleShooting] = useState(false);
    const [filess, setFiless] = useState([]);
    const onSendEmail = (event) => {
        event.preventDefault();
        if (troubleShooting && troubleShooting !== null && troubleShooting !== "") {       
            dispatch(TroubleShootingAction(filess, troubleShooting));
            setShowModal(false);
        } else {
            setIsDescFlag(true);
            toast.error('Please fill in the specify your problem section.', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    useEffect(() => {
        if (!showModal) {
            setUploadedFile([]);
            setTroubleShooting("");
            setFiless([]); 
            setIsDescFlag(false);
        }
    }, [showModal]);

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop: (acceptedFiles) => {
            const filesWithPreview = acceptedFiles.map((file) => ({
                file,
                preview: file.type.startsWith("image") ? URL.createObjectURL(file) : null,
            }));

            const filesWithPre = acceptedFiles.map((file) => ({
                file,
            }));
            setFiless((prevFiles) => [...prevFiles, ...filesWithPre]);
            setUploadedFile((prevFiles) => [...prevFiles, ...filesWithPreview]);
        },
        accept: {
            'application/pdf': [],
            'application/msword': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
            'text/plain': [],
            'text/log': [],
            'image/png': [],
            'image/jpeg': [],
            'application/json': [],
            'application/xml': [],
            'text/csv': [],
            'application/zip': []
        }
    });

    const removeFile = (index) => {
        setUploadedFile((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };
    const onChangeTroubleShooting = (event) => {
        event.preventDefault();
        setTroubleShooting(event.target.value);
    }

    const onClickTroubleShooting = (event) => {
        event.preventDefault();
        setIsTroubleShooting(event.target.value);
    }

    useEffect(() => {
        if (ShowModalFlagReducer) {
            if (ShowModalFlagReducer.data === true && ShowModalFlagReducer.show === "troubleShooting") {
                setShowModal(true);
            } else if (ShowModalFlagReducer.data === false && ShowModalFlagReducer.show === "troubleShooting") {
                setShowModal(false);
            }
        }
    }, [ShowModalFlagReducer])

    const cancelButton = (event) => {
        event.preventDefault();
        setShowModal(false);
    }

    const style = {
        zIndex: '999',
        marginTop: '10px',
        position: 'absolute',
        backgroundColor: 'white',
        width: '500px',
        boxShadow: '0 24px 54px rgba(0,0,0,.15), 0 4.5px 13.5px rgba(0,0,0,.08)',
        borderRadius: 10,
        textAlign: 'left',
        color: 'black',
    }

    const buttonStyle = {
        display: 'flex',
        flexDirection: 'row-reverse'
    }
    return (
        <div>
            <Modal onHide={() => setShowModal(false)} styleProps={style} onClose={() => setShowModal(false)} show={showModal} titleProps={documentTitle}>
                <div className="row mt-2">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="comment" style={{textAlign: 'justify'}}>
                                Your input is invaluable to us. We appreciate your assistance in identifying issues and will use this information to improve our site’s performance and resolve any technical problems. Please upload any relevant files or screenshots to help us better understand and address your concerns.
                            </label>
                            <div>
                                <label className="control-label">Explain Your Problem<span className="required">*</span></label>
                                <textarea id="comment" onChange={onChangeTroubleShooting} value={troubleShooting} className="form-control" required name="comment" style={{ borderColor: isDescFlag ? 'red' : 'green' }}>
                                </textarea>
                                <section style={{ height: '100%' }} className="upload w-100 p-4">
                                    <Container  {...getRootProps({ className: 'dropzone mt-3 p-3', isFocused, isDragAccept, isDragReject })}>
                                        <input {...getInputProps()} />
                                        <i style={{ color: '#28a8e2', fontSize: '80px' }} className="fa-solid fa-cloud-arrow-up"></i>
                                        <p>Choose a file or drag it here</p>
                                        <p style={{fontSize: '10px'}}>Supported file types: PDF, JPG, PNG, etc. </p>
                                    </Container>

                                    <div className="preview-grid">
                                        {uploadedFile.map((fileObj, index) => (
                                            <div className="preview-item" key={index}>

                                                {fileObj.preview ? (
                                                    <img
                                                        src={fileObj.preview}
                                                        alt={fileObj.file.name}
                                                        className="preview-image"
                                                    />
                                                ) : (
                                                    <div className="file-icon">
                                                        {fileObj.file.type.includes("pdf") && (
                                                            <i className="fa-solid fa-file-pdf" style={{ color: "#e74c3c" }}></i>
                                                        )}
                                                        {fileObj.file.type.includes("zip") && (
                                                            <i className="fa-solid fa-file-archive" style={{ color: "#f1c40f" }}></i>
                                                        )}
                                                        {!fileObj.file.type.includes("pdf") &&
                                                            !fileObj.file.type.includes("zip") && (
                                                                <i className="fa-solid fa-file" style={{ color: "#3498db" }}></i>
                                                            )}
                                                    </div>
                                                )}

                                                <div className="file-info">
                                                    {fileObj.file.name}
                                                </div>
                                                <button
                                                    className="remove-button"
                                                    onClick={() => removeFile(index)}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        ))}
                                        {
                                            uploadedFile.length === 0 && <div style={{ fontSize: '13px'}}>No files uploaded yet</div>
                                        }
                                    </div>

                                </section>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-m-4" style={buttonStyle}>
                        <button onClick={onSendEmail} className="btn btn-success m-3 lift">Submit</button>
                        <button onClick={cancelButton} className="btn btn-danger m-3 lift text-white">Cancel</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
/*
                        <div className="form-check">
                            <input className="form-check-input" onChange={onClickTroubleShooting} type="checkbox" name="feedbackemail" value={isTroubleShooting}></input>
                            <label className="form-check-label" htmlFor="feedback-email">Allow us to contact you regarding your issue</label>
                        </div>
*/
export default TroubleShooting;
import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import $ from 'jquery';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { PasswordlessLoginAction } from '../../actions/LinkEntryAction';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import booby from '../../images/Logo_removebg.png';
export default function PasswordlessLogin() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { entry, userId,token } = useParams();


    const PasswordlessLoginReducer = useSelector(state => state.PasswordlessLoginReducer);

    const [showOk, setShowOk] = useState(false);
    const [showErr, setShowErr] = useState(false);


    useEffect(() => {
        dispatch(PasswordlessLoginAction(entry, userId, token));
    }, []);
    
    
    useEffect(() => {

        if (PasswordlessLoginReducer && PasswordlessLoginReducer.data && PasswordlessLoginReducer.data.isSuccessful === true) {

            console.log("we are ok ", PasswordlessLoginReducer);
            var c = { "data": PasswordlessLoginReducer.data.data };

            localStorage.setItem('AccessToken', JSON.stringify(c));
            dispatch({
                type: 'LOGIN_SUCCESS',
                payload: PasswordlessLoginReducer.data.data
            });

            setShowOk(true);
            setShowErr(false);

            navigate("/index/linkEntry/" +entry);
        }
        else if (PasswordlessLoginReducer && PasswordlessLoginReducer.error && PasswordlessLoginReducer.error.isSuccessful === false) {
            setShowErr(true);
            console.log("errrrr");
            toast.error(PasswordlessLoginReducer.error.error.errors[0], {
                position: toast.POSITION.TOP_RIGHT
            })
            setShowOk(false);
        }

    }, [PasswordlessLoginReducer])


    return (<>
        <ToastContainer />
        <div className="row AuthTL">
            <div className="col-md-4 d-flex justify-content-center">
                <section className="">
                    <img src={booby} alt="Bymmy" />
                    {showOk && <p style={{
                        'fontWeight': 'bold', 'padding': '5px 10px'
                    }}>Please wait while redirecting...</p>}
                    {showErr && <p style={{
                        'fontWeight': 'bold', 'padding': '5px 10px'
                    }}>The code is not valid or expired already, please get new code...</p>}                  
                </section>
            </div>
        </div>
    </>)
}
import React from 'react';
import { Route, Routes} from 'react-router-dom';
import { Layout } from './components/Layout';
import './custom.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Index from "./components/Main/index";
import { Login } from './components/api-authorization/Login';
import  SignIn  from './components/api-authorization/SignIn';
import { RegisterConfirmation } from './components/api-authorization/registerConfirmation';
import  RegisterNotConfirmation  from './components/api-authorization/registerNotConfirmation';
import  ReSendMailConfirmation  from './components/api-authorization/reSendMailConfirmation';
import { ForgetPassword } from './components/api-authorization/forgetPassword';
import  ProtectedRoute  from "./components/api-authorization/protectedRoute";
import NotLayout from "./components/api-authorization/notLayout";
import ResetPassword from "./components/api-authorization/resetPassword";
import { ResetPasswordEmail } from "./components/api-authorization/resetPasswordEmail";
import { RegisterNotConfirmedPosted } from "./components/api-authorization/registerNotConfirmedPosted";
import Upload from "./components/Main/upload";
import New from "./components/Main/new";
import Account from "./components/Main/account";
import GeneralProfile from './components/Main/generalProfile';
import SharedWithMePage from './components/Main/sharedWithMePage';
import Edit from './components/Main/edit';
import RecycleBinPage from './components/Main/recycleBinPage';
import Viewer from './components/Main/viewer';
import BaseNavbar from './baseNavbar';
import Share from './components/Main/share';
import Uploadeds from './components/Main/uploadeds';
import Invoices from './components/Main/invoices';
import History from './components/Main/History'
import NotFound_404 from './components/notFound/notFound404';
import TermsOfService from './components/Main/terms_of_service';
import PrivacyPolicy from './components/Main/privacy_policy';
import LinkEntry from './components/Main/linkEntry';
import PasswordlessLogin from './components/api-authorization/passwordlessLogin';
import Payments from './components/Main/payment';
export default function App() {

    return (
        <>      
            <BaseNavbar />
            <Routes>
                <Route element={<NotLayout />}>
                    <Route exact path="/" element={<ProtectedRoute />}>
                        <Route exact path='/account' element={<Account />} />
                        <Route exact path='/account/generalProfile' element={<GeneralProfile />} />
                        <Route exact path='/account/uploadeds' element={<Uploadeds />} />
                        <Route exact path='/account/invoices' element={<Invoices />} />
                        <Route exact path='/account/history' element={<History />} />
                        <Route exact path='' element={<Index />} />
                        <Route exact path='/index' element={<Index />} />
                        <Route name="viewer" path='/index/viewer/:userId' element={<Viewer />} />
                        <Route name="edit" path='/index/edit/:userId' element={<Edit />} />
                        <Route exact path='/recycleBinPage' element={<RecycleBinPage />} />
                        <Route exact path='/upload' element={<Upload />} />
                        <Route exact path='/new' element={<New />} />
                        <Route exact path='/sharedWithMePage' element={<SharedWithMePage />} />
                        <Route name="share" path="/index/share/:userId" element={<Share />} />
                        <Route name="payments" path="/account/payments" element={<Payments />} />
                    </Route>
                </Route>
                <Route element={<Layout />}>
                    <Route exact path='SignIn/' element={<SignIn />} />
                    <Route exact path='/login' element={<Login />} />
                    <Route exact path='/registerConfirmation' element={<RegisterConfirmation />} />
                    <Route exact path='/registerNotConfirmation' element={<RegisterNotConfirmation />} />
                    <Route exact path='/forgetPassword' element={<ForgetPassword />} />
                    <Route exact path='/reSendMailConfirmation' element={<ReSendMailConfirmation />} />
                    <Route exact path='/registerNotConfirmedPosted' element={<RegisterNotConfirmedPosted />} />
                    <Route exact path='/resetPassword' element={<ResetPassword />} />
                    <Route exact path='/index/LinkEntry/:viewKey' element={<LinkEntry />} />
                    <Route exact path='/passwordless/:entry/:token/:userId' element={<PasswordlessLogin />} />
                    <Route exact path='/resetPasswordEmail' element={<ResetPasswordEmail />} />
                    <Route exact name="terms_of_service" path="/terms_of_service" element={<TermsOfService />} />
                    <Route exact name="privacy_policy" path="/privacy_policy" element={<PrivacyPolicy />} />
                </Route>
                <Route path='*' element={<NotFound_404 />} />
            </Routes> 
        </>

    );
}
//<Route name="statistics" path="index/statistics/:userId" element={<Statistics />} />
/*
 * 
 * 
 * 
 * 
 * 
 * <Route element={<SidebarMenuLayout />} >
                        <Route exact path='/index' element={<Index />} />
                        <Route exact path='/upload' element={<Upload />} />
                        <Route exact path='/new' element={<New />} />
                    </Route>
                    */


/*
 * 
 *           <PublicRoute restricted={false} component={Home} path="/" exact />
                <PublicRoute restricted={true} component={SignIn} path="/" exact />
                <PublicRoute restricted={true} component={Login} path="/" exact />
                <PublicRoute restricted={true} component={RegisterConfirmation} path="/" exact />
                <PublicRoute restricted={true} component={RegisterNotConfirmation} path="/" exact />
                <PublicRoute restricted={false} component={ForgetPassword} path="/" exact />
                <ProtectedRoute component={Index} path='/index' exact />  
 * 
 * 

/*  const AppRoutes = [
    {
        index: true,
    element: <Home />
},
    {
        path: '/SignIn',
    element: <SignIn />
},
    {
        path: '/Login',
    element: <Login />
},
    {
        path: '/registerConfirmation',
    element: <RegisterConfirmation />
},
    {
        path: '/registerNotConfirmation',
    element: <RegisterNotConfirmation />
},
    {
        path: '/forgetPassword',
    element: <ForgetPassword />
},
    {
        path: '/index',
    element: <Index />
},
    ];

        {AppRoutes.map((route, index) => {
            const { element, requireAuth, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
        })}
        */
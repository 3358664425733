import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../styles/style.css';
import '../styles/Responsive.css';
import '../styles/style_bundle.css';
export default function PaymentButton({ onClick}) {
    return (

        <button
            className="payment-button bg-success"
            onClick={onClick}
        >
            Make Payment
        </button>
    );
}
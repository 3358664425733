import React, { useState,useEffect, useRef } from 'react'
import '../styles/style.css';
import { useDispatch, useSelector } from 'react-redux';
import NavigationBar from './sideBarMenu';
import { useDropzone } from 'react-dropzone'
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate } from 'react-router-dom';
import { newPdfAction } from '../../actions/NewPdfAction';
import Spinner from 'react-bootstrap/Spinner';
import { SplashScreen } from '../../actions/splashScreen';
import { ToastContainer, toast } from 'react-toastify';

import styled from 'styled-components';
const getColor = (props) => {
    if (props.isDragAccept) {
        return '#52e3ff';
    }
    if (props.isDragReject) {
        return '#efbdbe';
    }
    if (props.isFocused) {
        return '#b7ccd0';
    }
    return '#eeeeee';
}

const Container = styled.div`
      background-color: ${props => getColor(props)};
      opacity: 0.8;
    `;


export default function Upload() {
    const dispatch = useDispatch();
    //const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [files, setFiles] = useState(false);
    const navigate = useNavigate();
    //const newPdfRedux = useSelector(state => state.newPdfRedux)
    //const inputFileRef = useRef(null);
    //const inputNameRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    //const [valid, setValid] = useState(false);
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: {
            'application/pdf' : []
        }
    });

    useEffect(() => {
        setFiles(false);
    }, []);

    /*const handleValidation = () => {

        let errors = {};
        let formIsValid = true;

        //File Name
        if (inputNameRef.current.value === "" ) {
            formIsValid = false;
            errors["name"] = "File Name Cannot be empty";
        }
               
        if (!inputFileRef.current.value) {
            formIsValid = false;
            errors["file"] = "File must be exist";
        }
       
        setErrors(errors);
        return formIsValid;
    }*/

    /*const handleSubmit = (event) => {
        event.preventDefault();
        if (handleValidation()) {
            
            setLoading(true);
            dispatch(newPdfAction(inputFileRef.current._wrapperState.initialValue, inputNameRef.current.value, description));
            dispatch(SplashScreen(true));
            navigate('/index');
        }        
    }

    const onChangeName = (event) => {
        setName(event.target.value);

    }

    const onChangeDescription = (event) => {
        setDescription(event.target.value);
    }*/

    useEffect(() => {

        if (acceptedFiles.length > 0) {
            setLoading(true);
            acceptedFiles.map((file) => {
                dispatch(newPdfAction(file, file.name, description));
                return true; 
            });
            dispatch(SplashScreen(true));
            navigate('/index');
        }

    }, [acceptedFiles]);

    useEffect(() => {
        if (fileRejections.length > 0) {
            fileRejections.map(({ file, errors }) => {
                errors.map(e => {
                    toast.error(e.code + " " + e.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    return true;
                });
                return true;
            });
        }
    }, [fileRejections]);

   
    

   /*const acceptedFileItems = acceptedFiles.map(file => (
        <div className="row" key={file.path}>
            <div className="col-12">
                {
                    
                    loading && <Spinner animation="border" variant="info" />
                }
            <form id="newform1" method="post" >            
                <h2>Share Content</h2>
                <div className="form-horizontal">
                        <hr></hr>
                        <div className="row">
                    <div className="col-md-6 col-sm-12">
                    <div className="form-group mb-3">
                        {errors && errors.file && <span style={{ color: 'red' }} >{errors.file}</span>}
                        <label className="control-label d-flex form-label col-md-2" htmlFor="Name">Name</label>
                                    <input type="text" className="form-control" onChange={onChangeName} ref={inputNameRef} id="formFile"  value={name} required />
                        {errors && errors.name && <span style={{ color: 'red' }} >{errors.name}</span>}
                    
                    </div>
                        

                <div className="form-group mb-3">
                        <label className="control-label form-label col-md-2" htmlFor="Description">Description</label>
                        <textarea className="form-control min-height-100" onChange={onChangeDescription} id="Description" name="Description" required></textarea>
                        {errors && errors.description && <span style={{ color: 'red' }} >{errors.description}</span>}
                </div>
                    {files && <span style={{color: 'green'}}>You upload sucessfully</span>}
                        
                        </div>
                        
                        <div className="col-md-6 col-sm-12">
                                {file &&
                                    <>
                                        <br/>
                                        <br/>
                                <div className="text-muted">
                                        <div><b>File Name:</b> {file.path}</div>
                                        <div><b>File Size:</b> {file.size} bytes</div>
                                    </div>
                                    
                                    <div className="form-group mt-5">
                                        
                                            <button id="btnsave" style={{ backgroundColor: '#28a8e2' }} onClick={handleSubmit} className="button  btn btn-success my-1">
                                                <span className="spinner-border spinner-border-sm" style={{ display: "none" }} role="status" aria-hidden="true"></span>
                                            <i className="fa-solid fa-cloud-arrow-up"></i> Upload Content
                                            </button>
                                        
                                    </div>
                                    <input style={{ display: "none" }} type="text" className="form-control" onChange={e => console.log(e)} value={file} ref={inputFileRef} required />
                                </>
                            }                    </div>
                            
                        </div>
                    </div>
                    
            </form>
            </div>
        </div>
    ));*/

    /*const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));*/



    return (
        <>
            <ToastContainer />
            <div className="mainDivRsT">
                <NavigationBar />
                <section style={{ height: '100%' }} className="upload app-wrapper w-100 p-5 mt-3">
                    <Container  {...getRootProps({ className: 'dropzone mt-3 p-5', isFocused, isDragAccept, isDragReject })}>
                        <input {...getInputProps()} />
                        <i style={{ color: '#28a8e2', fontSize: '80px' }} className="fa-solid fa-cloud-arrow-up"></i>
                        <p>Choose a file or drag it here</p>
                    </Container>
                
                </section>
            </div>
        </>
    );

}
/*
<div className="pt-5">       
{acceptedFileItems}
{fileRejectionItems}
   </div>
*/

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const SENDTROUBLESHOOTING_SUCCESS = 'SENDTROUBLESHOOTING_SUCCESS';
export const SENDTROUBLESHOOTING_FAILURE = 'SENDTROUBLESHOOTINGL_FAILURE';

export const TroubleShootingAction = (acceptedFiles, troubleShooting) => async (dispatch) => {
    try {
        const formData = new FormData();

        // Dosyaları ekle
        acceptedFiles.forEach((file) => {
            formData.append('files', file.file);
        });

        // Açıklamayı ekle
        formData.append('Description', troubleShooting);

        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;

        await axios.post('/api/Main/TroubleShooting', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
        }).then(function (response) {
            dispatch({
                type: SENDTROUBLESHOOTING_SUCCESS,
                payload: response.data,
            });
            toast.success('Problem reported sucessfully', {
                position: toast.POSITION.TOP_RIGHT,
            });
        });

    } catch (errors) {
        //not authenticated give error
        toast.error(errors.response.data.error.errors[0], {
            position: toast.POSITION.TOP_RIGHT
        });
        dispatch({
            type: SENDTROUBLESHOOTING_FAILURE,
            payload: errors,
        });

    };
}


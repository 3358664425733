import React, { useState, useEffect } from 'react';
import '../styles/login.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ForgetPasswordFormAction, ForgetPasswordFormVerifiedTokenAction } from '../../actions/forgetPasswordFormAction';
import booby from '../../images/Logo_removebg.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../custom.css';
export default function ResetPassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [code, setCode] = useState('');
    //const navigate = useNavigate();
    const forgetPasswordFormRedux = useSelector(state => state.forgetPasswordFormRedux);
    const forgetPasswordVerifiedToken = useSelector(state => state.forgetPasswordVerifiedToken);
    const [authorize, setAuthorize] = useState(true);
    const [input, setInput] = useState({
        userID: decodeURIComponent(window.location.search.indexOf("?ml") > -1 ? window.location.search.split("?ml=")[1] : ""),
        newPassword: '',
        confirmPassword: ''
    });

    const [error, setError] = useState({
        userID: '',
        newPassword: '',
        confirmPassword: '',
    });

    const InputChange = (event) => {
        const { name, value } = event.target;
        setInput(prev => ({
            ...prev,
            [name]: value
        }));
        validateInput(event);
    }

    const validateInput = (event) => {
        let { name, value } = event.target;
        setError(prev => {
            const stateObj = { ...prev, [name]: "" };

            switch (name) {
                case "newPassword":
                    if (!value) {
                        stateObj[name] = "Please enter Password.";
                    } else if (input.confirmPassword && value !== input.confirmPassword) {
                        stateObj["confirmPassword"] = " Password and Confirm Password does not match";
                    } else {
                        stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword;
                    }
                    break;
                case "confirmPassword":
                    if (!value) {
                        stateObj[name] = "Please enter Confirm Password.";
                    } else if (input.password && value !== input.password) {
                        stateObj[name] = "Password and Confirm Password does not match.";
                    }
                    break;
                default:
                    break;
            }
            return stateObj;
        })
    }


    useEffect(() => {
        if (forgetPasswordVerifiedToken) {
            if (forgetPasswordVerifiedToken.data) {
                setAuthorize(true);
            }
            else if (forgetPasswordVerifiedToken.error) {
                setAuthorize(false);
                setTimeout(function () {
                    navigate("/");
                }, 5000)
            }
        }
    }, [forgetPasswordVerifiedToken])

    useEffect(() => {
        var Coded;
        if (window.location.search.indexOf('code') > -1) {
            Coded = window.location.search.split('?')[1].split('code=')[1]
            setCode(window.location.search.split('?')[1].split('code=')[1]);
        }
        //dispatch(ForgetPasswordFormVerifiedTokenAction(input.userID, Coded));
    }, [])
    useEffect(() => {
        if (forgetPasswordFormRedux) {
            if (forgetPasswordFormRedux.data && forgetPasswordFormRedux.data.isSuccessful) {
                setTimeout(function () {
                    navigate("/");
                }, 3000)
            } else if (forgetPasswordFormRedux.error) {
                setInput(prev => ({
                    ...prev,
                    newPassword: '',
                    confirmPassword: '',
                }));
            }
        }
    }, [forgetPasswordFormRedux]);


    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(ForgetPasswordFormAction(input.userID, input.newPassword, code));
    }

    return (
        <>
            <ToastContainer />
            {authorize &&
                <div id="rstPsw" className="row AuthTL">

                    <div className="col-md-4 d-flex justify-content-center">
                        <section>
                            <form id="resetPasswordForm" method="post" onSubmit={handleSubmit}>
                                <img src={booby} alt="Bymmy App" style={{ 'float': 'right', 'marginRight': '-25px' }}></img>
                                <h2 style={{ 'fontWeight': 'bold' }}>Reset Password</h2>
                                <hr />
                                <div className="pb-3">
                                    <label className="form-label">New Password</label>
                                    <input type="password" name="newPassword" value={input.newPassword} onChange={InputChange} className="form-control" required />
                                    {error && error.password && <span className='errMsg'> - {error.password}</span>}
                                </div>
                                <div className="pb-3">
                                    <label className="form-label">Confirm Password</label>
                                    <input type="password" name="confirmPassword" value={input.confirmPassword} onChange={InputChange} className="form-control" required />
                                    {error && error.confirmPassword && <span className='errMsg'> - {error.confirmPassword}</span>}
                                </div>
                                <div className="pb-4">
                                    <button id="registerSubmit" type="submit" className="w-100 btn btn-lg btn-spf-grn">Reset Password</button>
                                </div>
                            </form>
                        </section>
                    </div>
                </div>
            }
        </>
    );

}
// {signInRedux.error && signInRedux.error.errors.response.data.errors.map(item => (<span style="font-size: 16px;color: red;">{item}</span>))}
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NavigationBar from './sideBarMenu';
import '../styles/style.css';
import '../styles/Responsive.css';
import '../styles/style_bundle.css';
import ButtonGroup from './buttonGroup';
import MyDocuments from './myDocuments';
import NavigationNames from "./navigationNames";
import { refreshTokenAction } from '../../actions/refreshTokenAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Statistics from './statistics';
import { StatisticId } from '../../actions/statisticsId';
import Rename from './rename';
import PaymentButton from './paymentButton';

import { CreateChargeAction } from '../../actions/CreateChargeAction';

export default function PaymentsBox({ isMobileView, paymentPlan, price, isSelected, onClick }) {
    const dispatch = useDispatch();
    const getUserInformationReducer = useSelector(state => state.getUserInformationReducer);
    const refreshTokenReducer = useSelector(state => state.refreshTokenReducer);
    const newPdfRedux = useSelector(state => state.newPdfRedux);
    const [statisticsFlag, setStatisticsFlag] = useState(false);
    const statisticIdReducer = useSelector(state => state.StatisticIdReducer);
    const DeleteMoreThanOneDocumentReducer = useSelector(state => state.DeleteMoreThanOneDocumentReducer);
    const ShowModalFlagReducer = useSelector(state => state.ShowModalFlagReducer);
    const [renameDocumentActive, setRenameDocumentActive] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const handleClick = (id) => {
        setSelectedId(id);
    }
    debugger;
    const handlePayment = () => {
        if (selectedId) {
            alert(`Seçilen ödeme planı: ${selectedId}`);
        }

        dispatch(CreateChargeAction({ Amount: price }) );

    };

    
    return (
        <div
            className={`czFgKT ${isMobileView ? 'mobile_w100' : ''} ${isSelected ? 'selected' : ''}`} // Seçili olan kutuya 'selected' sınıfını ekle
            onClick={onClick} // Tıklandığında onClick çalışır
            tabIndex={0} // Kutuların focus olabilmesi için
        >
            <div className="Header">
                <div>
                    <div className="tjKLFBm">
                        {paymentPlan}
                    </div>
                    <div className="MnVRtY text-success text-bold">
                        ${price}
                    </div>
                </div>
            </div>
            <hr />
            <div className="footerPayments">
                <PaymentButton
                    onClick={handlePayment}
                />
            </div>
        </div>
    );
}

import axios from 'axios';
import {  toast } from 'react-toastify';
export const CREATE_CHARGE = 'CREATE_CHARGE';
export const CREATE_CHARGE_SUCCESS = 'CREATE_CHARGE_SUCCESS';
export const CREATE_CHARGE_FAILURE = 'CREATE_CHARGE_FAILURE';

export const CreateChargeAction = (data) => async (dispatch) => {    

    const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
    const token = tokenStorage.data.accessToken;

    await axios.post('/api/payments/create-charge', data, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
        .then(function (response) {
            
            dispatch({
                type: CREATE_CHARGE_SUCCESS,
                payload: response.data,
            });
            toast.success('Payment request successfully created, redirecting Coinbase.', {
                position: toast.POSITION.TOP_RIGHT
            });
        }).catch(function (reason) {
            toast.error('Internal error, please try again later.', {
                position: toast.POSITION.TOP_RIGHT
            });
        });
}



export const CreateChargeReducer = (state = { data: null } , action) => {
    switch (action.type) {
        case CREATE_CHARGE_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case CREATE_CHARGE_FAILURE:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}